
import { Component, Vue } from 'vue-property-decorator'
import { WeatherQualityDetail } from '../../types/weatherQuality.d'
import echarts from 'echarts'
import { directionTransition } from '../../utils/formatData'

@Component({
  name: 'DataAnalyse'
})
export default class DataAnalyse extends Vue {
  private deviceInfo: { deviceNumber: string; deviceStatus: string } = {
    deviceNumber: '',
    deviceStatus: ''
  }

  private paramList: Array<{ icon: string; name: string; value: string }> = [{
    icon: 'wendu',
    name: '温度(°C)',
    value: ''
  }, {
    icon: 'shidu',
    name: '湿度(%RH)',
    value: ''
  }, {
    icon: 'jiangshuiliang',
    name: '降雨量(mm)',
    value: ''
  }, {
    icon: 'qiya',
    name: '气压(kPa)',
    value: ''
  }, {
    icon: 'fengxiang',
    name: '风向',
    value: ''
  }, {
    icon: 'pm25',
    name: 'PM2.5(μg/m³)',
    value: ''
  }, {
    icon: 'zaoyin',
    name: '噪音(db)',
    value: ''
  }, {
    icon: 'fengsu',
    name: '风速(m/s)',
    value: ''
  }, {
    icon: 'fuyang',
    name: '负氧离子(个/cm³)',
    value: ''
  }, {
    icon: 'guangzhao',
    name: '光照(Lux)',
    value: ''
  }, {
    icon: 'pm10',
    name: 'PM10(μg/m³)',
    value: ''
  }, {
    icon: 'eryanghuatan',
    name: '二氧化碳(ppm)',
    value: ''
  }, {
    icon: 'taiyangzongfushe',
    name: '太阳总辐射(mwh/cm²)',
    value: ''
  }, {
    icon: 'pm10',
    name: '总悬浮颗粒(μg/m³)',
    value: ''
  }]

  private searchInfo: { type: string; dateRange: Array<string> } = {
    type: '0',
    dateRange: []
  }

  private selectTime = 0
  private pickerOptions = {
    // 选中日期后会执行的回调
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.selectTime = date.minDate.getTime()
      if (date.maxDate) {
        this.selectTime = 0
      }
    },
    disabledDate: (time: any) => {
      if (this.selectTime !== 0) {
        const dayTime = 29 * 24 * 3600 * 1000
        const minTime = this.selectTime - dayTime
        const maxTime = this.selectTime + dayTime
        return (time.getTime() < minTime || time.getTime() > maxTime)
      }
    }
  }

  private echart: any

  get deviceId () {
    return this.$route.params.deviceId as string
  }

  // 0-温度，1-湿度，2-降雨量，3-气压，4-风向，5-风速，6-负氧离子，7-噪音，8-pm2.5
  get typeList () {
    const arr = new Map<string, string>()
    arr.set('13', '总悬浮颗粒(μg/m³)')
    arr.set('11', '太阳总辐射(mwh/cm²)')
    arr.set('9', '二氧化碳(ppm)')
    arr.set('12', 'PM10(μg/m³)')
    arr.set('10', '光照(Lux)')

    arr.set('6', '负氧离子(个/cm³)')
    arr.set('5', '风速(m/s)')
    arr.set('7', '噪音(db)')
    arr.set('8', 'PM2.5(μg/m³)')
    arr.set('4', '风向(°)')
    arr.set('3', '气压(kPa)')
    arr.set('2', '降雨量(mm)')
    arr.set('1', '湿度(%RH)')
    arr.set('0', '温度(°C)')
    return arr
  }

  // 0-温度，1-湿度，2-降雨量，3-气压，4-风向，5-风速，6-负氧离子，7-噪音，8-pm2.5
  get typeColor () {
    const arr = new Map<string, string[]>()
    arr.set('13', ['rgba(109, 212, 0, 1)', 'rgba(109, 212, 0, 0.2)'])
    arr.set('12', ['rgba(109, 212, 0, 1)', 'rgba(109, 212, 0, 0.2)'])
    arr.set('11', ['rgba(255, 158, 88, 1)', 'rgba(255, 158, 88, 0.2)'])
    arr.set('10', ['rgba(255, 158, 88, 1)', 'rgba(255, 158, 88, 0.2)'])
    arr.set('9', ['rgba(24, 144, 255, 1)', 'rgba(24, 144, 255, 0.2)'])

    arr.set('6', ['rgba(24, 144, 255, 1)', 'rgba(24, 144, 255, 0.2)'])
    arr.set('5', ['rgba(54, 203, 203, 1)', 'rgba(54, 203, 203, 0.2)'])
    arr.set('7', ['rgba(255, 113, 120, 1)', 'rgba(255, 113, 120, 0.2)'])
    arr.set('8', ['rgba(109, 212, 0, 1)', 'rgba(109, 212, 0, 0.2)'])
    arr.set('4', ['rgba(54, 203, 203, 1)', 'rgba(54, 203, 203, 0.2)'])
    arr.set('3', ['rgba(90, 112, 220, 1)', 'rgba(90, 112, 220, 0.2)'])
    arr.set('2', ['rgba(19, 177, 244, 1)', 'rgba(19, 177, 244, 0.2)'])
    arr.set('1', ['rgba(19, 177, 244, 1)', 'rgba(19, 177, 244, 0.2)'])
    arr.set('0', ['rgba(255, 158, 88, 1)', 'rgba(255, 158, 88, 0.2)'])
    return arr
  }

  $refs!: {
    echart: any;
  }

  created () {
    this.initTime()
    this.getData()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  initTime () {
    const date = new Date()
    const val = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date.getDate())
    this.searchInfo.dateRange = [val, val]
  }

  addZero (val: number) {
    return val < 10 ? '0' + val : String(val)
  }

  getData (): void {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      const title = this.typeList.get(this.searchInfo.type) as string
      const color = (this.typeColor.get(this.searchInfo.type) as string[])[0]
      const shadowColor = (this.typeColor.get(this.searchInfo.type) as string[])[1]
      this.$axios.get(this.$apis.weatherQuality.selectWeatherQualityDetail, {
        deviceId: this.deviceId,
        type: this.searchInfo.type,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1]
      }).then((res: WeatherQualityDetail) => {
        this.deviceInfo.deviceNumber = res.deviceData.deviceNumber
        this.deviceInfo.deviceStatus = res.deviceData.deviceStatus
        this.paramList[0].value = res.deviceData.temperature
        this.paramList[1].value = res.deviceData.humidity
        this.paramList[2].value = res.deviceData.precipitation
        this.paramList[3].value = res.deviceData.pressure
        this.paramList[4].value = directionTransition(res.deviceData.direction)
        this.paramList[5].value = res.deviceData.pm25
        this.paramList[6].value = res.deviceData.noise
        this.paramList[7].value = res.deviceData.speed
        this.paramList[8].value = res.deviceData.negativeOxyge
        this.paramList[9].value = res.deviceData.lux
        this.paramList[10].value = res.deviceData.pm10
        this.paramList[11].value = res.deviceData.co2
        this.paramList[12].value = res.deviceData.zfs
        this.paramList[13].value = res.deviceData.tsp
        this.draw(title, [color], shadowColor, res.dataTimeList || [], res.dataList || [])
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  draw (title: string, color: Array<string>, shadowColor: string, xData: Array<string>, yData: Array<string>) {
    this.$nextTick(() => {
      this.echart = echarts.init(this.$refs.echart)
      const name = title.indexOf('(') > 0 ? title.substring(0, title.indexOf('(')) : title
      const option: any = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: 80,
          left: 10,
          right: 30,
          bottom: 0,
          containLabel: true
        },
        title: {
          text: title,
          textStyle: {
            fontSize: 18,
            fontWeight: 400,
            color: '#000000'
          }
        },
        legend: {
          icon: 'rect',
          top: 40,
          left: 0,
          itemWidth: 20,
          itemHeight: 1,
          itemGap: 5,
          textStyle: {
            color: '#8C8C8C'
          }
        },
        backgroundColor: 'transparent',
        color: color,
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: '#999999'
            }
          },
          data: xData
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: name,
          type: 'line',
          symbolSize: 0,
          smooth: true,
          itemStyle: {
            normal: {
              borderWidth: 2
            }
          },
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: shadowColor,
            shadowOffsetY: 20
          },
          data: yData
        }]
      }

      this.echart.setOption(option)
    })
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }
}
